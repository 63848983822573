<template>
	<div class="px-4 login_bg">
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<v-col
					cols="12"
					align="center"
					class="pa-0 mb-10 layout_align login"
				>
					<v-col
						align="center"
						class="pa-0"
					>
						<h2 class="content_title">
							회원가입 유형
							<p class="sub_title">고객님께 해당하는 유형을 선택해 주세요.</p>
						</h2>
					</v-col>

					<v-col
						class="pa-4 pa-sm-6 section"
						cols="12"
					>
						<v-col
							class="pa-0 pt-2 pt-sm-6 pb-4 mb-5 btn_join join_user"
							cols="12"
							@click="$router.replace('/login/signupUser')"
							@mouseover="handleIconMouseOver('JoinUserIconColor')"
							@mouseout="handleIconMouseOut('JoinUserIconColor')"
						>
							<v-row
								class="ma-0 mb-0 mb-sm-2"
								justify="center"
							>
								<v-col
									class="pa-0"
									cols="auto"
									align-self="center"
								>
									<CommonIconsJoinUserIcon :color="JoinUserIconColor" />
								</v-col>
								<v-col
									class="pa-0 pl-1 pl-sm-2 tit"
									align="left"
									align-self="center"
									cols="auto"
								>
									일반회원
								</v-col>
							</v-row>
							<p class="notice">14세 이상 개인</p>
						</v-col>

						<v-col
							class="pa-0 pt-2 pt-sm-6 pb-4 btn_join join_interior"
							cols="12"
							@click="$router.replace('/login/signupStore')"
							@mouseover="handleIconMouseOver('JoinInteriorIconColor')"
							@mouseout="handleIconMouseOut('JoinInteriorIconColor')"
						>
							<v-row
								class="ma-0 mb-0 mb-sm-2"
								justify="center"
							>
								<v-col
									class="pa-0"
									cols="auto"
									align-self="center"
								>
									<CommonIconsJoinInteriorIcon :color="JoinInteriorIconColor" />
								</v-col>
								<v-col
									class="pa-0 pl-1 pl-sm-2 tit"
									align="left"
									align-self="center"
									cols="auto"
								>
									인테리어점 회원
								</v-col>
							</v-row>
							<p class="notice">사업자</p>
						</v-col>
					</v-col>
				</v-col>
			</v-container>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoginSignUp',
	metaInfo: {
		title: '회원가입 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '회원가입 | 동화자연마루',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루 회원가입',
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	data: () => ({
		JoinUserIconColor: '#9E9E9E',
		JoinInteriorIconColor: '#9E9E9E',
	}),
	watch: {},
	methods: {
		handleIconMouseOut(icon) {
			this[icon] = '#9E9E9E'
		},
		handleIconMouseOver(icon) {
			this[icon] = '#78C046'
		},
	},
}
</script>

<style lang="scss" scoped>
.login_bg {
	background-color: $color_gray_1;
	height: 100%;
}
.login {
	max-width: 400px !important;
}
.section {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.btn_join {
	cursor: pointer;
	border: 1px solid $color_gray_4;
	border-radius: 8px;
	.tit {
		font-weight: $fw_regular;
		font-size: $font_tit;
		color: $color_font;
	}
	.notice {
		font-weight: $fw_normal;
		font-size: $font_lg;
		color: $color_gray_7;
	}
}

.btn_join:hover {
	transition: all 0.1s;
	border: 1px solid $color_primary_1;
	.tit,
	.notice {
		transition: all 0.1s;
		color: $color_primary_1;
	}
}

@media all and (max-width: 600px) {
	.btn_join {
		.tit {
			font-size: $font_xl !important;
		}
		.notice {
			font-size: $font_normal !important;
		}
	}
}
</style>
